import moment from 'moment-timezone';
import {
  FetchablePhysicalMagnitude,
  FetchableMeasurementPace,
} from '../services/business-data/dto/measurement.interface';

export const minPrmLength = (prms) => {
  const prmList = prms.split(/\n/);
  const trimmedPrm = prmList.filter((el) => el !== '');
  return trimmedPrm.length > 0;
};

export const maxPrmLength = (prms) => {
  const prmList = prms.split(/\n/);
  const trimmedPrm = prmList.filter((el) => el !== '');
  return trimmedPrm.length <= 200;
};

export const formatPrm = (prms) => {
  const prmList = prms.split(/\n/);
  const trimmedPrm = prmList.filter((el) => el !== '');
  return trimmedPrm.every((currentValue) => currentValue.length !== 0);
};

export const minPrmLengthArray = (prms) => {
  const trimmedPrm = prms.filter((el) => el !== '');
  return trimmedPrm.length > 0;
};

export const maxPrmLengthArray = (prms) => {
  const trimmedPrm = prms.filter((el) => el !== '');
  return trimmedPrm.length <= 200;
};

export const formatPrmArray = (prms) => {
  const trimmedPrm = prms.filter((el) => el !== '');
  return trimmedPrm.every((currentValue) => currentValue.length !== 0);
};

export const isStartDateBeforeEndDate = (_, store) => {
  const startDate = moment(store._data.startDateInput);
  const endDate = moment(store._data.endDateInput).add(1, 'day');

  if (!startDate.isBefore(endDate)) return false;

  return true;
};

export const isValidPaceWithApparentPhysicalMagnitude = (_, store) => {
  if (
    store._data.selectedPhysicalMagnitude ===
      FetchablePhysicalMagnitude.ApparentPower &&
    store._data.selectedPace === FetchableMeasurementPace.Raw
  )
    return false;

  return true;
};

export const isValidPaceWithSeveralDeliveryPoints = (_, store) => {
  if (
    store._props.profiles.length > 1 &&
    store._data.selectedPace === FetchableMeasurementPace.Raw
  )
    return false;

  return true;
};
