export enum FetchablePhysicalMagnitude {
  ActivePower = 'Active',
  InductiveReactivePower = 'InductiveReactive',
  CapacitiveReactivePower = 'CapacitiveReactive',
  ApparentPower = 'Apparent',
}

export enum FetchableMeasurementPace {
  Raw = 'RAW',
  PT10M = 'PT10M',
  PT60M = 'PT60M',
}

export interface ExportMeasurementPerimeter {
  prm: string;
  startDate: Date;
  endDate: Date;
  physicalMagnitude?: FetchablePhysicalMagnitude;
  pace?: FetchableMeasurementPace;
}

export interface ExportMeasurementParameters {
  measurementPerimeter: ExportMeasurementPerimeter;
  userEmail: string;
}

export interface ExportReadingPerimeter {
  prm: string;
  startDate: Date;
  endDate: Date;
}

export interface ExportReadingParameters {
  readingPerimeter: ExportReadingPerimeter;
  userEmail: string;
}
