import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    themes: [
      {
        light: {
          primary: {
            base: '#0047BB',
            darken3: '#309CAE',
          },
          secondary: {
            base: '#0077BA',
            darken3: '#1C5590',
          },
        },
      },
    ],
  },
});
