declare global {
  interface Window {
    _mtm: any;
  }
}

export class MatomoTagManager {
  public static initializeMatomo() {
    window._mtm = window._mtm || [];

    window._mtm.push({
      'mtm.startTime': new Date().getTime(),
      event: 'mtm.Start',
    });

    const matomoScript = document.createElement('script');
    matomoScript.async = true;
    matomoScript.src =
      'https://matomo.collectifenergie.com/js/container_PP5H4Y7d_dev_0717e0ebfd390ffd2ddfe707.js';
  }

  public static dataLayerEvent(name: string) {
    window._mtm.push({ event: 'purchase', name });
  }
}
