import Vue from 'vue';
import Axios from 'axios';
import config from './config/config';

import vuetify from './plugins/vuetify';

import store from './store';
import router from './router';

import App from './App.vue';
import './base.scss';

import Vuelidate from 'vuelidate';
import VueMatomo from 'vue-matomo';
import { MatomoTagManager } from './services/MatomoTagManager.service';

const matomoTagManager = MatomoTagManager.initializeMatomo;
const matomoDataLayer = MatomoTagManager.dataLayerEvent;

Vue.use(VueMatomo, {
  host: config.matomo.host,
  siteId: config.matomo.siteId,
});
Vue.use(matomoTagManager);
Vue.use(matomoDataLayer);

Vue.use(Vuelidate);

Vue.prototype.$http = Axios;
Vue.prototype.$http.defaults.baseURL = config.enedisApi.baseUrl;

Vue.config.productionTip = false;

new Vue({
  store,
  router,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
