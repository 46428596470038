<template>
  <div class="d-flex mb-12">
    <div
      :style="{
        width: '15%',
        minWidth: '12rem',
      }"
    >
      <div>
        <div>
          <v-card
            class="pa-2"
            :style="{
              backgroundColor: 'var(--secondary-grey-new)',
              border: 'none',
            }"
            outlined
          >
            <p
              class="title__home mt-1 mb-2"
              :style="{ whiteSpace: 'nowrap', color: 'var(--primary-new)' }"
              align="center"
            >
              VOTRE DOSSIER
            </p>
            <v-form :style="{ backgroundColor: 'var(--white)' }">
              <v-textarea
                id="form-prm-textarea"
                v-model.trim="prms"
                hide-details
                outlined
                :error-messages="prmsErrors"
                label="PRMs"
                rows="20"
                required
                data-test="prm"
                @input="$v.prms.$touch()"
                @blur="$v.prms.$touch()"
                :style="{
                  fontSize: '0.9em',
                }"
              >
                <template slot="append">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon :style="{ top: '5px' }" v-bind="attrs" v-on="on">
                        mdi-information-outline
                      </v-icon>
                    </template>
                    <span>1 PRM par ligne. 200 lignes maximum</span>
                  </v-tooltip>
                </template>
              </v-textarea>
              <p
                v-if="prmsErrors.length"
                class="error-message"
                :style="{
                  fontSize: '0.8em',
                  color: 'var(--red-new)',
                  backgroundColor: 'var(--secondary-grey-new) !important',
                }"
              >
                {{ prmsErrors.join() }}
              </p>
            </v-form>
          </v-card>
        </div>
      </div>
    </div>

    <div class="pr-2" :style="{ width: '85%' }">
      <div>
        <v-tabs
          v-model="tab"
          align-with-title
          background-color="var(--secondary-grey-new)"
        >
          <v-tab
            v-for="item in items"
            :key="item.title"
            :style="{
              backgroundColor: 'var(--secondary-grey-new)',
              border: 'none',
            }"
          >
            {{ item.title }}
          </v-tab>
        </v-tabs>
      </div>

      <div>
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="item in items" :key="item.title">
            <component
              v-bind:is="item.component"
              :prms="prmList"
              :validation="checkForm"
              :startDate="startDate"
              :endDate="endDate"
              :startDateBeforeEndDate="startDateBeforeEndDate"
              :periodIsYear="periodIsYear"
              @startDateModifier="startDateModifier"
              @endDateModifier="endDateModifier"
            ></component>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </div>
  </div>
</template>

<script>
  import { required } from 'vuelidate/lib/validators';
  import {
    minPrmLength,
    maxPrmLength,
    formatPrm,
  } from '../validators/enedisValidators';
  import moment from 'moment-timezone';

  export default {
    name: 'HomePage',
    data() {
      return {
        tab: null,
        items: [
          {
            title: 'DT & CONSO',
            component: () =>
              import(
                '../components/consumption-analyser/DonneesTechniquesTabs'
              ),
          },
          {
            title: 'COURBE DE PUISSANCE',
            component: () =>
              import('../components/measurements/CourbeDePuissanceTabs'),
          },
          {
            title: 'OPTIMISATION TURPE',
            component: () =>
              import('../components/acheminement/OptimisationTabs'),
          },
          {
            title: 'SAISIE DE CONTRAT',
            component: () =>
              import(
                '../components/salesforce-contract/SfdcContractCreationTabs'
              ),
          },
          {
            title: 'IMPORT PFC / SPOT',
            component: () =>
              import('../components/price-forward-curve/PriceForwardCurveTabs'),
          },
        ],
        prms: '',
        startDate: '',
        endDate: '',
      };
    },
    validations: {
      prms: {
        required,
        minPrmLength,
        maxPrmLength,
        formatPrm,
      },
    },
    watch: {
      prms(newPrm) {
        localStorage.prm = newPrm;
      },
      startDate(newDateDebut) {
        localStorage.startDate = newDateDebut;
      },
      endDate(newDateFin) {
        localStorage.endDate = newDateFin;
      },
    },
    computed: {
      prmList() {
        return this.prms.split(/\n/).filter((e) => e !== '');
      },
      prmsErrors() {
        const errors = [];
        if (!this.$v.prms.$dirty) return errors;
        !this.$v.prms.minPrmLength &&
          errors.push('Veuillez renseigner un ou plusieurs PRM');
        !this.$v.prms.maxPrmLength &&
          errors.push('200 Prms maximum par recherche');
        return errors;
      },
      startDateBeforeEndDate() {
        if (this.startDate && this.endDate) {
          const startDate = moment(this.startDate);
          const endDate = moment(this.endDate).add(1, 'day');

          if (!startDate.isBefore(endDate)) {
            return false;
          }
        }

        return true;
      },
      periodIsYear() {
        if (this.startDate && this.endDate) {
          const startDate = moment(this.startDate);
          const endDate = moment(this.endDate).add(1, 'day');

          if (endDate.diff(startDate, 'years', true) !== 1) {
            return false;
          }
        }

        return true;
      },
    },
    mounted() {
      this.prms = localStorage.prm || '';
      this.startDate = localStorage.startDate || '';
      this.endDate = localStorage.endDate || '';
    },
    methods: {
      checkForm() {
        this.$v.$touch();

        return !this.$v.$invalid;
      },
      startDateModifier(startDate) {
        this.startDate = startDate;
      },
      endDateModifier(endDate) {
        this.endDate = endDate;
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import 'src/client/style/constants.scss';
  #form-prm-textarea {
    overflow-y: auto;
  }

  .v-input--selection-controls {
    margin-top: 0px !important;
  }

  .title {
    &__home {
      font-size: 1.3em;
      font-weight: 500;
    }
  }
</style>
