var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex mb-12"},[_c('div',{style:({
      width: '15%',
      minWidth: '12rem',
    })},[_c('div',[_c('div',[_c('v-card',{staticClass:"pa-2",style:({
            backgroundColor: 'var(--secondary-grey-new)',
            border: 'none',
          }),attrs:{"outlined":""}},[_c('p',{staticClass:"title__home mt-1 mb-2",style:({ whiteSpace: 'nowrap', color: 'var(--primary-new)' }),attrs:{"align":"center"}},[_vm._v(" VOTRE DOSSIER ")]),_c('v-form',{style:({ backgroundColor: 'var(--white)' })},[_c('v-textarea',{style:({
                fontSize: '0.9em',
              }),attrs:{"id":"form-prm-textarea","hide-details":"","outlined":"","error-messages":_vm.prmsErrors,"label":"PRMs","rows":"20","required":"","data-test":"prm"},on:{"input":function($event){return _vm.$v.prms.$touch()},"blur":function($event){return _vm.$v.prms.$touch()}},model:{value:(_vm.prms),callback:function ($$v) {_vm.prms=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"prms"}},[_c('template',{slot:"append"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({style:({ top: '5px' })},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}])},[_c('span',[_vm._v("1 PRM par ligne. 200 lignes maximum")])])],1)],2),(_vm.prmsErrors.length)?_c('p',{staticClass:"error-message",style:({
                fontSize: '0.8em',
                color: 'var(--red-new)',
                backgroundColor: 'var(--secondary-grey-new) !important',
              })},[_vm._v(" "+_vm._s(_vm.prmsErrors.join())+" ")]):_vm._e()],1)],1)],1)])]),_c('div',{staticClass:"pr-2",style:({ width: '85%' })},[_c('div',[_c('v-tabs',{attrs:{"align-with-title":"","background-color":"var(--secondary-grey-new)"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.items),function(item){return _c('v-tab',{key:item.title,style:({
            backgroundColor: 'var(--secondary-grey-new)',
            border: 'none',
          })},[_vm._v(" "+_vm._s(item.title)+" ")])}),1)],1),_c('div',[_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.items),function(item){return _c('v-tab-item',{key:item.title},[_c(item.component,{tag:"component",attrs:{"prms":_vm.prmList,"validation":_vm.checkForm,"startDate":_vm.startDate,"endDate":_vm.endDate,"startDateBeforeEndDate":_vm.startDateBeforeEndDate,"periodIsYear":_vm.periodIsYear},on:{"startDateModifier":_vm.startDateModifier,"endDateModifier":_vm.endDateModifier}})],1)}),1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }