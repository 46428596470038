import axios from 'axios';
import { MatomoService } from '../../../services/MatomoApi.service';

const actions = {
  async me({ commit }) {
    try {
      const res = await axios.get('/auth/me', {
        withCredentials: true,
      });

      commit('setCurrentUser', res.data);
      MatomoService.setUserId(res.data.displayName);

      return res;
    } catch (e) {
      console.error(e);
    }
  },
  async logout({ commit }) {
    try {
      await axios.post('/auth/forcedotcom/logout', {
        withCredentials: true,
      });

      commit('logout');
    } catch (err) {
      console.log(err);
    }
  },
};

export default actions;
