import Vue from 'vue';
import VueRouter from 'vue-router';

import store from '../store';

import HomePage from '../views/HomePage.vue';
import LoginPage from '../views/LoginPage.vue';
import LogoutPage from '../views/LogoutPage.vue';
import NotFoundPage from '../views/NotFoundPage.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/login',
    name: 'LoginPage',
    component: LoginPage,
  },
  {
    path: '/logout',
    name: 'LogoutPage',
    component: LogoutPage,
  },
  {
    path: '*',
    component: NotFoundPage,
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // Hack a cause de la redirection via le server
    await store.dispatch('auth/me');
    if (store.getters['auth/isLoggedIn']) {
      next();
      return;
    }
    next('/login');
  } else {
    next();
  }
});

export default router;
