<template>
  <div class="logout-page">
    <h3 class="mb-10" align="center">Vous êtes bien déconnecté</h3>
    <v-row justify="center" align="center">
      <a :href="apiUrl" :style="{ textDecoration: 'none' }">
        <v-btn color="primary">Connexion avec Salesforce</v-btn>
      </a>
    </v-row>
  </div>
</template>

<script>
  import Vue from 'vue';

  export default {
    name: 'LogoutPage',
    data() {
      return {
        apiUrl: `${Vue.prototype.$http.defaults.baseURL}/auth/forcedotcom`,
      };
    },
  };
</script>
