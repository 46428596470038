import { io } from 'socket.io-client';

// need to manually connect
const socket = io(process.env.VUE_APP_API_URL, {
  autoConnect: true,
  forceNew: false,
});

// Connect and Reconnect
socket.on('connect', () => {
  console.log(`Connected ${socket.id}`);
});

// Connection cannot be established or denied by the server
socket.on('connect_error', () => {
  console.log(`Unable to establish connection`);
});

// Disconnection
socket.on('disconnect', (reason) => {
  console.log(`Disconnected: ${socket.id} : ${reason}`);
});

// For Development: Log event
if (process.env.NODE_ENV !== 'production') {
  socket.onAny((event, ...args) => {
    console.log(event, args);
  });
}

export default socket;
