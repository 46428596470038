<template>
  <v-app>
    <v-app-bar app fixed :style="{ backgroundColor: 'var(--primary-new)' }">
      <div class="mr-4" :style="{ width: '5rem' }">
        <img :src="logo" alt="" :style="{ width: '100%' }" />
      </div>
      <v-toolbar-title> Service Enedis </v-toolbar-title>
      <v-spacer></v-spacer>
      <div class="v-toolbar__user" v-if="user">
        {{ user.displayName }}
      </div>
      <v-btn
        v-if="isLoggedIn"
        color="primary"
        @click="logout"
        text
        data-test="disconnection"
      >
        <v-icon :style="{ color: 'var(--white)' }">logout</v-icon>
      </v-btn>
    </v-app-bar>
    <v-main>
      <div>
        <router-view></router-view>
      </div>
    </v-main>
    <v-footer>
      <div>
        &copy; {{ new Date().getFullYear() }} —
        <strong>Collectif Energie</strong>
      </div>
      <v-spacer></v-spacer>
      <div>version: {{ version }}</div>
      <v-spacer></v-spacer>
      <div>Build: {{ buildDate }}</div>
    </v-footer>
  </v-app>
</template>

<script>
  import moment from 'moment';
  import socket from './socket';
  import logo from './assets/img/logo-white.png';

  import { mapGetters, mapState } from 'vuex';

  export default {
    name: 'App',
    data() {
      return {
        logo,
        buildDate: '',
        version: '',
      };
    },
    computed: {
      ...mapGetters({
        isLoggedIn: 'auth/isLoggedIn',
      }),
      ...mapState('auth', ['user']),
    },
    created() {
      socket.connect();

      socket.on('courbe-puissance:job-left', (response) => {
        this.$store.dispatch('measurements/initJobLeft', response.jobLeft);
      });
      socket.on('transport:job-left', (response) => {
        this.$store.dispatch('transports/initJobLeft', response.jobLeft);
      });

      this.version = process.env.VUE_APP_VERSION;
      // Display build date which has been injected during code build from index.html
      this.buildDate = moment(
        document.documentElement.dataset.buildTimestampUtc
      ).format('DD-MM-YYYY HH:mm:ss');

      // If we receive a 401 from API, we try to access to unauthorize endpoint then Logout
      this.$http.interceptors.response.use(undefined, (err) => {
        return new Promise(() => {
          if (
            err.response &&
            err.response.status === 401 &&
            err.config &&
            !err.config.__isRetryRequest
          ) {
            this.$router.push({ name: 'LoginPage' });
          }
          throw err;
        });
      });
    },
    methods: {
      logout() {
        this.$store.dispatch('auth/logout').then(() => {
          this.$router.push({ name: 'LogoutPage' });
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .v-app-bar {
    color: var(--white);
  }
  .v-toolbar {
    &__title {
      font-size: 1.2em;
      font-weight: 500;
    }

    &__user {
      margin-left: 2rem;
      font-size: 1em;
    }
  }
  .v-main {
    background-color: var(--secondary-grey-new);
  }
  .v-footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    font-size: 0.8em;
  }
</style>
