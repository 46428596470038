<template>
  <div class="login-page">
    <h1 class="primary--text mb-10" align="center">
      Veuillez vous connecter afin d'accéder au service
    </h1>
    <v-row justify="center" align="center">
      <v-col md="4" sm="12">
        <a :href="apiUrl" ref="login"></a>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import Vue from 'vue';

  export default {
    name: 'LoginPage',
    data() {
      return {
        apiUrl: `${Vue.prototype.$http.defaults.baseURL}/auth/forcedotcom`,
      };
    },
    mounted() {
      this.$refs.login.click();
    },
  };
</script>
